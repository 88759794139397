import React, { FC } from "react";
import "./MainSlider.css";

interface MainSliderProps {}

const MainSlider: FC<MainSliderProps> = () => {
  return (
    <>
      <div className="container-fluid py-3">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="owl-carousel owl-carousel-2 carousel-item-1 position-relative mb-3 mb-lg-0">
                <div className="position-relative overflow-hidden heightfourthirtyfive">
                  <img
                    className="img-fluid h-100 imgwhc"
                    src="img/news-700x435-1.jpg"
                  />
                  <div className="overlay">
                    <div className="mb-1">
                      <a className="text-white" href="">
                        Technology
                      </a>
                      <span className="px-2 text-white">/</span>
                      <a className="text-white" href="">
                        January 01, 2045
                      </a>
                    </div>
                    <a className="h2 m-0 text-white font-weight-bold" href="">
                      Sanctus amet sed amet ipsum lorem. Dolores et erat et
                      elitr sea sed
                    </a>
                  </div>
                </div>
                <div className="position-relative overflow-hidden heightfourthirtyfive">
                  <img
                    className="img-fluid h-100 imgwhc"
                    src="img/news-700x435-2.jpg"
                  />
                  <div className="overlay">
                    <div className="mb-1">
                      <a className="text-white" href="">
                        Technology
                      </a>
                      <span className="px-2 text-white">/</span>
                      <a className="text-white" href="">
                        January 01, 2045
                      </a>
                    </div>
                    <a className="h2 m-0 text-white font-weight-bold" href="">
                      Sanctus amet sed amet ipsum lorem. Dolores et erat et
                      elitr sea sed
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="d-flex align-items-center justify-content-between bg-light py-2 px-4 mb-3">
                <h3 className="m-0">Categories</h3>
                <a
                  className="text-secondary font-weight-medium text-decoration-none"
                  href=""
                >
                  View All
                </a>
              </div>
              <div className="position-relative overflow-hidden mb-3 heighteighty">
                <img
                  className="img-fluid w-100 h-100 imgwhc"
                  src="img/cat-500x80-1.jpg"
                />
                <a
                  href=""
                  className="overlay align-items-center justify-content-center h4 m-0 text-white text-decoration-none"
                >
                  Business
                </a>
              </div>
              <div className="position-relative overflow-hidden mb-3 heighteighty">
                <img
                  className="img-fluid w-100 h-100 imgwhc"
                  src="img/cat-500x80-2.jpg"
                />
                <a
                  href=""
                  className="overlay align-items-center justify-content-center h4 m-0 text-white text-decoration-none"
                >
                  Technology
                </a>
              </div>
              <div className="position-relative overflow-hidden mb-3 heighteighty">
                <img
                  className="img-fluid w-100 h-100 imgwhc"
                  src="img/cat-500x80-3.jpg"
                />
                <a
                  href=""
                  className="overlay align-items-center justify-content-center h4 m-0 text-white text-decoration-none"
                >
                  Entertainment
                </a>
              </div>
              <div className="position-relative overflow-hidden heighteighty">
                <img
                  className="img-fluid w-100 h-100 imgwhc"
                  src="img/cat-500x80-4.jpg"
                />
                <a
                  href=""
                  className="overlay align-items-center justify-content-center h4 m-0 text-white text-decoration-none"
                >
                  Sports
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainSlider;
