import React, { FC } from "react";
import "./Home.css";
import Topbar from "../Topbar/Topbar";
import Navbar from "../Navbar/Navbar";
import TopSlider from "../TopSlider/TopSlider";
import MainSlider from "../MainSlider/MainSlider";
import FeaturedSlider from "../FeaturedSlider/FeaturedSlider";
import CategorySlider1 from "../CategorySlider1/CategorySlider1";
import CategorySlider2 from "../CategorySlider2/CategorySlider2";
import Sidebar from "../Sidebar/Sidebar";

interface HomeProps {}

const Home: FC<HomeProps> = () => {
  return (
    <>
      <Topbar />
      <Navbar />
      <TopSlider />
      <MainSlider />
      <FeaturedSlider />
      <CategorySlider1 />
      <CategorySlider2 />
      <Sidebar />
    </>
  );
};

export default Home;
