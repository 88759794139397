import React, { FC } from "react";
import "./Topbar.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

interface TopbarProps {}

const Topbar: FC<TopbarProps> = () => {
  const options = {
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 1,
      },
      700: {
        items: 1,
      },
      800: {
        items: 2,
      },
    },
    nav: false,
    dots: true,
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row align-items-center bg-light px-lg-5">
          <div className="col-12 col-md-8">
            <div className="d-flex justify-content-between">
              <div
                className="bg-primary text-white text-center py-2"
                id="trending"
              >
                Trending
              </div>
              <OwlCarousel
                className="owl-theme section"
                loop
                margin={20}
                autoplay
                {...options}
              >
                {/* 1 */}
                <div className="review item margintops">
                  <p>
                    <a href="">Kerala Entarnce Previous...</a>
                  </p>
                  <div className="review-img">
                    <img src="/trust.svg" alt="" />
                  </div>
                </div>

                {/* 2 */}
                <div className="review item margintops">
                  <p>
                    <a href="">Kerala Engineering Medical...</a>
                  </p>

                  <div className="review-img">
                    <img src="/globe.svg" alt="" />
                  </div>
                </div>
              </OwlCarousel>
            </div>
          </div>
          <div className="col-md-4 text-right d-none d-md-block">
            Monday, January 01, 2045
          </div>
        </div>
        <div className="row align-items-center py-2 px-lg-5">
          <div className="col-lg-4">
            <a href="" className="navbar-brand d-none d-lg-block">
              <h1 className="m-0 display-5 text-uppercase sitetitle">
                <span className="text-primary sitetitle">College</span>Searchers
              </h1>
            </a>
          </div>
          <div className="col-lg-8 text-center text-lg-right">
            <img className="img-fluid" src="img/ads-700x70.jpg" alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Topbar;
