import React, { FC } from "react";
import "./FeaturedSlider.css";

interface FeaturedSliderProps {}

const FeaturedSlider: FC<FeaturedSliderProps> = () => {
  return (
    <>
      <div className="container-fluid py-3">
        <div className="container">
          <div className="d-flex align-items-center justify-content-between bg-light py-2 px-4 mb-3">
            <h3 className="m-0">Featured</h3>
            <a
              className="text-secondary font-weight-medium text-decoration-none"
              href=""
            >
              View All
            </a>
          </div>
          <div className="owl-carousel owl-carousel-2 carousel-item-4 position-relative">
            <div className="position-relative overflow-hidden heightthreehundred">
              <img
                className="img-fluid w-100 h-100 imgwhc"
                src="img/news-300x300-1.jpg"
              />
              <div className="overlay">
                <div className="mb-1 fontsizethirteen">
                  <a className="text-white" href="">
                    Technology
                  </a>
                  <span className="px-1 text-white">/</span>
                  <a className="text-white" href="">
                    January 01, 2045
                  </a>
                </div>
                <a className="h4 m-0 text-white" href="">
                  Sanctus amet sed ipsum lorem
                </a>
              </div>
            </div>
            <div className="position-relative overflow-hidden heightthreehundred">
              <img
                className="img-fluid w-100 h-100 imgwhc"
                src="img/news-300x300-2.jpg"
              />
              <div className="overlay">
                <div className="mb-1 fontsizethirteen">
                  <a className="text-white" href="">
                    Technology
                  </a>
                  <span className="px-1 text-white">/</span>
                  <a className="text-white" href="">
                    January 01, 2045
                  </a>
                </div>
                <a className="h4 m-0 text-white" href="">
                  Sanctus amet sed ipsum lorem
                </a>
              </div>
            </div>
            <div className="position-relative overflow-hidden heightthreehundred">
              <img
                className="img-fluid w-100 h-100 imgwhc"
                src="img/news-300x300-3.jpg"
              />
              <div className="overlay">
                <div className="mb-1 fontsizethirteen">
                  <a className="text-white" href="">
                    Technology
                  </a>
                  <span className="px-1 text-white">/</span>
                  <a className="text-white" href="">
                    January 01, 2045
                  </a>
                </div>
                <a className="h4 m-0 text-white" href="">
                  Sanctus amet sed ipsum lorem
                </a>
              </div>
            </div>
            <div className="position-relative overflow-hidden heightthreehundred">
              <img
                className="img-fluid w-100 h-100 imgwhc"
                src="img/news-300x300-4.jpg"
              />
              <div className="overlay">
                <div className="mb-1 fontsizethirteen">
                  <a className="text-white" href="">
                    Technology
                  </a>
                  <span className="px-1 text-white">/</span>
                  <a className="text-white" href="">
                    January 01, 2045
                  </a>
                </div>
                <a className="h4 m-0 text-white" href="">
                  Sanctus amet sed ipsum lorem
                </a>
              </div>
            </div>
            <div className="position-relative overflow-hidden heightthreehundred">
              <img
                className="img-fluid w-100 h-100 imgwhc"
                src="img/news-300x300-5.jpg"
              />
              <div className="overlay">
                <div className="mb-1 fontsizethirteen">
                  <a className="text-white" href="">
                    Technology
                  </a>
                  <span className="px-1 text-white">/</span>
                  <a className="text-white" href="">
                    January 01, 2045
                  </a>
                </div>
                <a className="h4 m-0 text-white" href="">
                  Sanctus amet sed ipsum lorem
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FeaturedSlider;
